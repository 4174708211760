import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import HTMLFlipBook from 'react-pageflip';

const Newsletter = () => {
    const [showPopup1, setShowPopup1] = useState(false);
    const [showPopup2, setShowPopup2] = useState(false);
    const [showPopup3, setShowPopup3] = useState(false);
    const [showPopup4, setShowPopup4] = useState(false);
    const [target, setTarget] = useState(null);
    
    const togglePopup1 = () => {
        setShowPopup1(!showPopup1);
    };

    const togglePopup2 = () => {
        setShowPopup2(!showPopup2);
    };
    const togglePopup3 = () => {
        setShowPopup3(!showPopup3);
    };
    const togglePopup4 = () => {
        setShowPopup4(!showPopup4);
    };
    return (
        <>
            <section className="page-header">
                <div className="page-header-bg"></div>
                <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt" /></LazyLoad></div>
                <div className="container">
                    <div className="page-header__inner">
                        <ul className="thm-breadcrumb list-unstyled ml-0">
                            <li><Link to="/"> Home</Link></li>
                            <li>Magazine   </li>
                            <li>  Newsletter </li>
                        </ul>
                        <h2> Newsletter </h2>
                    </div>
                </div>
            </section>
            <section className="innerbody">
                <div className="container">
                    <div className="row years year2023 showYear magazine g-5">



                        <div className="col-lg-4 col-md-6">
                            <div className="magbox">
                                <h5>December 2024 Issue II</h5>
                                <LazyLoad> <img src="https://webapi.entab.info/api/image/LCSD/public/Images/December-24-1.jpg" className="img-fluid mx-auto d-block" alt="December 2024 Issue II" /></LazyLoad>
                            </div>
                            <div className="popup_main">
                                <button className="open_popup" name="data" type="button" onClick={togglePopup4}>Click to View</button>
                                {showPopup4 && (
                                    <div className="popup_body ">
                                        <div className="popup_back"></div>
                                        <div className="popup_contain">
                                            <div className="popup_close" onClick={togglePopup4}>x</div>
                                             <div className="flipOpen">
           
              <HTMLFlipBook
                width={500}
                height={680}
                minWidth={315}
                maxWidth={1000}
                minHeight={400}
                maxHeight={1200}
                mobileScrollSupport={true}
                showCover={true}
                useMouseEvents={true}
                className="flipPageComponent"
                onChange={(e) => setTarget(e)}
                pageBackground="rgb(255, 255, 255)"
                uncutPages="true"
              >
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/1.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/2.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/3.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/4.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/5.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/6.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/7.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/8.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/9.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/10.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/11.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/12.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/13.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/14.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/15.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/16.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/17.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/19.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/20.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/21.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/22.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/23.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/24.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/25.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/26.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/27.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/28.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/29.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/30.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/31.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/32.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/34.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/35.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/36.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/37.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/38.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/39.webp" /></div>
                <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/december-2024/40.webp" /></div>

              </HTMLFlipBook>
           
        </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>



                        <div className="col-lg-4 col-md-6">
                            <div className="magbox">
                                <h5>August 2024 Issue 1</h5>
                                <LazyLoad> <img src="https://webapi.entab.info/api/image/LCSD/public/Images/august-24-1.jpg" className="img-fluid mx-auto d-block" alt="July 2023 Issue 1" /></LazyLoad>
                            </div>
                            <div className="popup_main">
                                <button className="open_popup" name="data" type="button" onClick={togglePopup3}>Click to View</button>
                                {showPopup3 && (
                                    <div className="popup_body ">
                                        <div className="popup_back"></div>
                                        <div className="popup_contain">
                                            <div className="popup_close" onClick={togglePopup3}>x</div>
                                            <div className="flipOpen">
           
           <HTMLFlipBook
             width={500}
             height={680}
             minWidth={315}
             maxWidth={1000}
             minHeight={400}
             maxHeight={1200}
             mobileScrollSupport={true}
             showCover={true}
             useMouseEvents={true}
             className="flipPageComponent"
             onChange={(e) => setTarget(e)}
             pageBackground="rgb(255, 255, 255)"
             uncutPages="true"
           >
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/1.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/2.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/3.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/4.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/5.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/6.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/7.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/8.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/9.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/10.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/11.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/12.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/13.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/14.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/15.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/16.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/17.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/19.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/20.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/21.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/22.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/23.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/24.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/25.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/26.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/27.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/august-2024/28.webp" /></div> 

           </HTMLFlipBook>
        
     </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="magbox">
                                <h5>October 2023 </h5>
                                <LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter-2.jpg" className="img-fluid mx-auto d-block" alt="October 2023" /></LazyLoad>
                            </div>
                            <div className="popup_main">
                                <button className="open_popup" name="data" type="button" onClick={togglePopup1}>Click to View</button>
                                {showPopup1 && (
                                    <div className="popup_body">
                                        <div className="popup_back"></div>
                                        <div className="popup_contain">
                                            <div className="popup_close" onClick={togglePopup1}>x</div>
                                            <div className="flipOpen">
           
           <HTMLFlipBook
             width={500}
             height={680}
             minWidth={315}
             maxWidth={1000}
             minHeight={400}
             maxHeight={1200}
             mobileScrollSupport={true}
             showCover={true}
             useMouseEvents={true}
             className="flipPageComponent"
             onChange={(e) => setTarget(e)}
             pageBackground="rgb(255, 255, 255)"
             uncutPages="true"
           >
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/1.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/2.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/3.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/4.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/5.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/6.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/7.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/8.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/9.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/10.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/11.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/12.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/13.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/14.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/15.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/october-2024/16.webp" /></div> 

           </HTMLFlipBook>
        
     </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="magbox">
                                <h5>July 2023 Issue 1</h5>
                                <LazyLoad>  <img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter-1.jpg" className="img-fluid mx-auto d-block" alt="July 2023 Issue 1" /></LazyLoad>
                            </div>
                            <div className="popup_main">
                                <button className="open_popup" name="data" type="button" onClick={togglePopup2}>Click to View</button>
                                {showPopup2 && (
                                    <div className="popup_body">
                                        <div className="popup_back"></div>
                                        <div className="popup_contain">
                                            <div className="popup_close" onClick={togglePopup2}>x</div>
                                            <div className="flipOpen">
           
           <HTMLFlipBook
             width={500}
             height={680}
             minWidth={315}
             maxWidth={1000}
             minHeight={400}
             maxHeight={1200}
             mobileScrollSupport={true}
             showCover={true}
             useMouseEvents={true}
             className="flipPageComponent"
             onChange={(e) => setTarget(e)}
             pageBackground="rgb(255, 255, 255)"
             uncutPages="true"
           >
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/1.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/2.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/3.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/4.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/5.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/6.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/7.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/8.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/9.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/10.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/11.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/12.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/13.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/14.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/15.webp" /></div>
             <div><img src="https://webapi.entab.info/api/image/LCSD/public/Images/newsletter/july-2024/16.webp" /></div> 

           </HTMLFlipBook>
        
     </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default Newsletter;
