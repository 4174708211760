import React, { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const TopperX = () => {
   const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // Initialize the selected year to the current session year
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const Year = `${currentYear - 1}-${currentYear}`;
    setSelectedYear(Year);
  }, []);

  // Fetch data and initialize year ranges
  useEffect(() => {
    const fetchData = async () => {
      const galleryData1 = await getTopper();
      const galleryData = galleryData1.filter((item) => item.class === 'X');
      setData(galleryData);

      // Extract unique session years
      const uniqueYears = Array.from(
        new Set(galleryData.map((item) => item.sessionYear))
      ).sort((a, b) => b.localeCompare(a)); // Sort years in descending order

      setYearRanges(uniqueYears);

      // Check if default year has data; if not, fallback to the last available year
      const currentYear = new Date().getFullYear();
      const defaultYear = `${currentYear - 1}-${currentYear}`;
      const hasDefaultYearData = galleryData.some(
        (item) => item.sessionYear === defaultYear
      );

      if (!hasDefaultYearData && uniqueYears.length > 0) {
        setSelectedYear(uniqueYears[0]); // Set the most recent year as active
      }
    };

    fetchData();
  }, []);

  // Filter data based on the selected year and search query
  useEffect(() => {
    let filteredData = data;

    // Filter by selected year
    if (selectedYear !== "All") {
      filteredData = filteredData.filter((item) => item.sessionYear === selectedYear);
    }

    // Filter by search query
    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Sort data by division in descending order
    filteredData.sort((a, b) => parseFloat(b.division) - parseFloat(a.division));

    setFilteredData(filteredData);
  }, [selectedYear, searchQuery, data]);

   return (
      <>
         <section className="page-header">
            <div className="page-header-bg"></div>
            <div className="page-header-shape-1"><LazyLoad><img src="https://webapi.entab.info/api/image/LCSD/public/Images/page-header-shape-1.png" alt="Loreto Convent School, Delhi Cantt"/></LazyLoad></div>
            <div className="container">
               <div className="page-header__inner">
                  <ul className="thm-breadcrumb list-unstyled ml-0">
                     <li><Link to="/"> Home</Link></li>
                     <li> Achievements</li>
                     <li> Topper X  </li>
                  </ul>
                  <h2>Topper X </h2>
               </div>
            </div>
         </section>
         <section className="innerbody">
            <div className="container">
               <div className="row tabs-dynamic">
                  <div className="col-md-6">
                     <div className="month-selection">
                        <select
                           value={selectedYear}
                           onChange={(e) => setSelectedYear(e.target.value)}
                           className="yearSelection"
                        >
                           <option value={"All"}>All</option>
                           {yearRanges.map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                           ))}
                        </select>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="searchBox">
                        <input
                           type="text"
                           id="myInput"
                           name="name"
                           value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value)}
                           placeholder="Search Here.."
                        />
                     </div>
                  </div>
               </div>
               <div className="row  years year2023 showYear">
                  {filterData.length > 0 &&
                     filterData.map((item, index) => (
                        <div className="col-md-3" key={index}>
                           <div className="topper-section services-style-current-theme text-center mb-lg-50 innertopper">
                              <div className="topper-service">
                                 <div className="thumb">
                                <LazyLoad>    <img className="rounded-circle" src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="Loreto Convent School Delhi Cantt" /></LazyLoad>
                                 </div>
                                 <div className="details clearfix">
                                    <h4 className="title">  {item?.name} </h4>
                                    <p>{item?.stream}</p>
                                    <ul className="topper-info-list">
                                       <li><span className="font-weight-800 text-theme-colored3">Marks:</span> {item?.division}</li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))}
               </div>
            </div>
         </section>
      </>
   );
};

export default TopperX;
